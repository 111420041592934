// setSelectedFeedQueryDraftOption.js
'use strict';
import { SET_OPERATION_DATA, REMOVE_OPERATION_DATA } from '../ActionTypes.js';

import {
  getSelectedDraftQueryOptionObject,
  getCandidateQueryOptionObject,
} from '../selector/getFeedQueryObject.js';
import getOperationData from '../selector/getOperationData.js';
import getSelectedFeedQueryOptions from '../selector/getSelectedFeedQueryOptions.js';

import fetchFeedsTotalCount from '../action/fetchFeedsTotalCount.js';
import setSelectedFeedQueryOption from '../action/setSelectedFeedQueryOption.js';

import { OPTION_UNSET, QueryType } from '../resource/feedConstants.js';

/**
 * Set selected feed query draft option
 * @kind action
 * @param {QueryType} {queryType} - query type.
 * @param {string} {option} - select filter option.
 * @param {string} {key} - select filter option category key.
 * @param {string} {remoteConfigKeyName} - remote config key.
 * @param {string} {baseFeedName} - base feed name.
 * @param {boolean} {isReset} - clear selected options.
 * @param {boolean} {isRestore} - resotre selected option to draft option.
 * @return {Promise} Action promise.
 */
const setSelectedFeedQueryDraftOption =
  ({
    queryType,
    option,
    key,
    remoteConfigKeyName,
    baseFeedName,
    isReset,
    isRestore,
  } = {}) =>
  async (dispatch, getState) => {
    if (isRestore) {
      const selectedOptions = getSelectedFeedQueryOptions(
        getState(),
        remoteConfigKeyName,
        queryType
      );
      return dispatch({
        type: SET_OPERATION_DATA,
        payload: {
          selectPath: ['feed', baseFeedName, queryType, 'draftOptions'],
          data: selectedOptions,
        },
      });
    }
    if (isReset) {
      dispatch({
        type: REMOVE_OPERATION_DATA,
        payload: {
          selectPath: ['feed', baseFeedName, queryType, 'draftOptions'],
        },
      });
    } else if (OPTION_UNSET === option) {
      const options =
        getCandidateQueryOptionObject(
          getState(),
          remoteConfigKeyName,
          queryType
        )?.[key] || [];
      const selectedDraftOptions =
        getOperationData(
          getState(),
          ['feed', baseFeedName, queryType],
          'draftOptions'
        ) || [];
      dispatch({
        type: SET_OPERATION_DATA,
        payload: {
          selectPath: ['feed', baseFeedName, queryType, 'draftOptions'],
          data: selectedDraftOptions.filter(
            option => !options.includes(option)
          ),
        },
      });
    } else {
      const currentOptions =
        // Can only selected one sort option
        (QueryType.SORT === queryType
          ? []
          : getOperationData(
              getState(),
              ['feed', baseFeedName, queryType],
              'draftOptions'
            )) || [];
      const optionIndex = currentOptions.indexOf(option);
      const isSelected = optionIndex >= 0;
      if (isSelected) {
        currentOptions.splice(optionIndex, 1);
        dispatch({
          type: SET_OPERATION_DATA,
          payload: {
            selectPath: ['feed', baseFeedName, queryType, 'draftOptions'],
            data: currentOptions,
          },
        });
      } else {
        dispatch({
          type: SET_OPERATION_DATA,
          payload: {
            selectPath: ['feed', baseFeedName, queryType, 'draftOptions'],
            data: [...currentOptions, option],
          },
        });
      }
    }
    if (QueryType.FILTER === queryType) {
      const { feedNameWithQuery } = getSelectedDraftQueryOptionObject(
        getState(),
        baseFeedName
      );
      return dispatch(
        fetchFeedsTotalCount({
          type: feedNameWithQuery,
        })
      );
    }
    if (QueryType.SORT === queryType) {
      return dispatch(
        setSelectedFeedQueryOption({
          queryType,
          baseFeedName,
          remoteConfigKeyName,
        })
      );
    }
    return dispatch({ type: '' });
  };

export default setSelectedFeedQueryDraftOption;
