// fetchFeedsTotalCount.js
'use strict';
import debounce from 'lodash/debounce';

import fetch from '../resource/customFetch.js';
import { getReturnType } from '../action/fetchFeeds.js';
import getMeData from '../selector/getMeData.js';
import getResourceUrl from '../resource/getResourceUrl.js';
import { getHeaders } from '../resource/fetchOptionHeader.js';
import handleFetchError from '../resource/handleFetchError.js';
import { parseQueryOptions } from '../resource/feedUtils.js';

import {
  ADD_LIST_ITEMS,
  SET_NETWORKING_FETCHING,
  SET_NETWORKING_SUCCESS,
  SET_NETWORKING_ERROR,
} from '../ActionTypes.js';

const debounceFunctionObject = {};
const debouncedFetchFeedsTotalCount =
  (payload = {}) =>
  dispatch => {
    const { type } = payload;
    const key = type;
    let handler = debounceFunctionObject[key];
    if (!handler) {
      handler = debounce(
        payload => dispatch(fetchFeedsTotalCountCore(payload)),
        1000 // TODO: remote config
      );
      debounceFunctionObject[key] = handler;
    }
    return handler(payload);
  };

/**
 * Fetch feeds total count
 * @kind action
 * @param {string} {type} - feed name.
 * @param {string} {id} - feed id.
 * @param {object} {[httpProxyHeaders = {}]} - http proxy headers for SSR.
 * @return {Promise} Action promise.
 */
export const fetchFeedsTotalCountCore =
  ({ type, id, httpProxyHeaders = {} } = {}) =>
  async (dispatch, getState) => {
    if (!type) {
      return dispatch({ type: '' });
    }
    const feedId = id || type;
    const returnType = getReturnType({ feedName: type });
    const listSelectPath = ['home', returnType, type];
    const networkingSelectPath = [...listSelectPath, 'totalCount'];
    const token = getMeData(getState(), 'token');
    const fetchOptions = {
      method: 'HEAD',
      headers: {
        ...getHeaders(),
        ...httpProxyHeaders,
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
    };
    const { feedNameWithQuery } = parseQueryOptions({
      options: [feedId],
    });
    const fetchUrl = getResourceUrl({
      endpoint: `/feeds/${feedNameWithQuery}`,
    });
    dispatch({
      type: SET_NETWORKING_FETCHING,
      payload: { selectPath: networkingSelectPath },
    });
    try {
      let response = await fetch(fetchUrl.href, fetchOptions);
      if (!response.ok) {
        response = await handleFetchError({
          response,
          dispatch,
          getState,
          fetchOptions,
          fetchUrl,
        });
      }
      const totalCount = parseInt(response.headers.get('x-total-count'), 10);
      dispatch({
        type: ADD_LIST_ITEMS,
        payload: {
          selectPath: listSelectPath,
          itemIds: [],
          totalCount,
        },
      });
      return dispatch({
        type: SET_NETWORKING_SUCCESS,
        payload: { selectPath: networkingSelectPath },
      });
    } catch (error) {
      return dispatch({
        type: SET_NETWORKING_ERROR,
        payload: { selectPath: networkingSelectPath, error },
      });
    }
  };

export default debouncedFetchFeedsTotalCount;
