// setSelectedFeedQueryOption.js
'use strict';
import { REMOVE_OPERATION_DATA } from '../ActionTypes.js';

import { replace } from '../action/navigationAction.js';
import toggleModal from '../action/toggleModal.js';

import getOperationData from '../selector/getOperationData.js';
import getSelectedFeedQueryOptions from '../selector/getSelectedFeedQueryOptions.js';

import { QueryType, QUERY_SEPARATOR } from '../resource/feedConstants.js';
import { parseQueryOptions } from '../resource/feedUtils.js';

/**
 * Set selected feed query option
 * @kind action
 * @param {QueryType} {queryType} - query type.
 * @param {string} {baseFeedName} - base feed name.
 * @param {string} {remoteConfigKeyName} - remote config key name.
 * @return {Promise} Action promise.
 */
const setSelectedFeedQueryOption =
  ({ queryType, baseFeedName, remoteConfigKeyName } = {}) =>
  async (dispatch, getState) => {
    let selectedFilterOptions = getSelectedFeedQueryOptions(
      getState(),
      remoteConfigKeyName,
      QueryType.FILTER
    );
    let selectedSortOptions = getSelectedFeedQueryOptions(
      getState(),
      remoteConfigKeyName,
      QueryType.SORT
    );
    switch (queryType) {
      case QueryType.FILTER: {
        selectedFilterOptions =
          getOperationData(
            getState(),
            ['feed', baseFeedName, queryType],
            'draftOptions'
          ) || [];
        break;
      }
      case QueryType.SORT: {
        selectedSortOptions =
          getOperationData(
            getState(),
            ['feed', baseFeedName, queryType],
            'draftOptions'
          ) || [];
        break;
      }
    }
    const { intersectedFeedNames, filters, sorts } = parseQueryOptions({
      options: [...selectedFilterOptions, ...selectedSortOptions],
    });
    const url = new URL(window.location.href);
    if (intersectedFeedNames.length) {
      url.searchParams.set('feed', intersectedFeedNames.join(QUERY_SEPARATOR));
    } else {
      ['feed', '_feed'].forEach(key => url.searchParams.delete(key));
    }
    if (filters.length) {
      url.searchParams.set('filters', filters.join(QUERY_SEPARATOR));
    } else {
      ['filters', '_filters'].forEach(key => url.searchParams.delete(key));
    }
    if (sorts.length) {
      url.searchParams.set('sorts', sorts.join(QUERY_SEPARATOR));
    } else {
      ['sorts', '_sorts'].forEach(key => url.searchParams.delete(key));
    }
    const modalId =
      QueryType.FILTER === queryType
        ? 'FilterMenu'
        : QueryType.SORT === queryType
          ? 'SortMenu'
          : undefined;
    if (modalId) {
      await dispatch(
        toggleModal({
          modalId,
          isOpen: false,
        })
      );
    }
    dispatch({
      type: REMOVE_OPERATION_DATA,
      payload: {
        selectPath: ['feed', baseFeedName, queryType, 'draftOptions'],
      },
    });

    return dispatch(
      replace({
        search: url.search,
      })
    );
  };

export default setSelectedFeedQueryOption;
